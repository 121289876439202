/**
 * @file login.tsx
 * File to handle server-side login logic (action) and the login UI component.
 */

import { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node";
import {
  Form,
  Link,
  json,
  redirect,
  useActionData,
  useNavigation,
} from "@remix-run/react";
import { Loader2 } from "lucide-react";
import { Button } from "~/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import { getSupabaseWithHeaders } from "~/utils/supabase.server";

type ActionData = {
  error?: string;
  success?: boolean;
};

// Used manily to make sure user is not logged in
export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { supabase, headers } = getSupabaseWithHeaders({
    request,
  });

  const {
    data: { user },
  } = await supabase.auth.getUser();

  // user already signed in with valid session
  if (user) {
    return redirect("/", { headers });
  }

  return json({ headers });
};

// handles sign in server side
export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const email = formData.get("email")?.toString();
  const password = formData.get("password")?.toString();

  if (!email || !password) {
    return json({ error: "Invalid form submission" }, { status: 400 });
  }

  const { supabase, headers } = getSupabaseWithHeaders({
    request,
  });

  const { error } = await supabase.auth.signInWithPassword({ email, password });

  if (error) {
    return { error: error.message };
  }

  return redirect("/", { headers });
};

export default function Login() {
  const actionData = useActionData<ActionData>();
  const navigation = useNavigation();
  const isSubmitting = navigation.state !== "idle";
  return (
    <div className="flex items-center justify-center min-h-screen bg-secondary px-10">
      <Card className="w-full max-w-xl sm:mx-auto bg-card">
        <CardHeader>
          <CardTitle>Packster Login</CardTitle>
        </CardHeader>
        <CardContent className="text-foreground">
          <Form method="post" className="space-y-6">
            <div>
              <label className="block text-sm font-medium">
                Email
                <input
                  type="email"
                  name="email"
                  className="w-full px-3 py-2 mt-1 border border-border rounded-md shadow-sm focus:outline-none focus:ring-ring focus:border-primary sm:text-sm"
                  required
                />
              </label>
            </div>
            <div>
              <label className="block text-sm font-medium">
                Password
                <input
                  type="password"
                  name="password"
                  className="w-full px-3 py-2 mt-1 border border-border rounded-md shadow-sm focus:outline-none focus:ring-ring focus:border-primary sm:text-sm"
                  required
                />
              </label>
            </div>
            {!isSubmitting ? (
              <Button
                type="submit"
                disabled={isSubmitting}
                className="w-full px-4 py-2 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ring"
                name="submitLogin"
              >
                Login
              </Button>
            ) : (
              <Button disabled className="w-full px-4 py-2 rounded-md">
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Please wait
              </Button>
            )}
          </Form>
          {actionData?.error && (
            <p className="text-red-600">{actionData.error}</p>
          )}
        </CardContent>
        <CardFooter className="flex justify-between text-sm text-card-foreground">
          <Link
            to="/signup"
            className="hover:underline hover:text-primary rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          >
            Sign up
          </Link>
          <Link
            to="/forgotpassword"
            className="hover:underline hover:text-primary rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          >
            I forgot my password
          </Link>
        </CardFooter>
      </Card>
    </div>
  );
}
